<template>
    <div>
        <b-container>
            <section class="profile">
                <b-row>
                    <b-col cols="12">
                        <div v-if="!paymentLoaded" class="sector pl-0 pr-0">
                            <Spinner size="medium" line-fg-color="#e91e63" />
                        </div>
                        <div v-else class="sector pl-0 pr-0">
                            <h2 class="profile__title mb-4">Edit payment</h2>
                            <div class="profile__content">
                                <p v-if="updatePaymentForm.localError" class="custom-error mb-3">{{ updatePaymentForm.localError }}</p>
                                <p v-if="getAdminPaymentsError" class="custom-error mb-3"> {{ getAdminPaymentsError }} </p>
                                <form :class="getDisabledAdminPayment ? 'disabled-element' : ''" @submit.prevent="updateAdminPayment" autocomplete="off">
                                    <b-row>
                                        <b-col cols="12">
                                            <span class="profile__label float-right">*Required fields</span>
                                        </b-col>
                                        <b-col cols="12" lg="6">
                                            <div class="profile__element">
                                                <label class="profile__label">Uid*</label>
                                                <input :disabled="getDisabledAdminPayment" v-model.trim="updatePaymentForm.uid" @blur="$v.updatePaymentForm.uid.$touch()" :class="[{'input-error' : $v.updatePaymentForm.uid.$error }, getDisabledAdminPayment ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type user id" autocomplete="off" />
                                                <div v-if="$v.updatePaymentForm.uid.$error">
                                                    <p v-if="!$v.updatePaymentForm.uid.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updatePaymentForm.uid.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Transaction id*</label>
                                                <input :disabled="getDisabledAdminPayment" v-model.trim="updatePaymentForm.transactionId" @blur="$v.updatePaymentForm.transactionId.$touch()" :class="[{'input-error' : $v.updatePaymentForm.transactionId.$error }, getDisabledAdminPayment ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type payment transaction id" autocomplete="off" />
                                                <div v-if="$v.updatePaymentForm.transactionId.$error">
                                                    <p v-if="!$v.updatePaymentForm.transactionId.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updatePaymentForm.transactionId.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Subscription type* (2,3,4)</label>
                                                <input :disabled="getDisabledAdminPayment" v-model.trim="updatePaymentForm.subscriptionType" @blur="$v.updatePaymentForm.subscriptionType.$touch()" :class="[{'input-error' : $v.updatePaymentForm.subscriptionType.$error }, getDisabledAdminPayment ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type payment subscription type" autocomplete="off" />
                                                <div v-if="$v.updatePaymentForm.subscriptionType.$error">
                                                    <p v-if="!$v.updatePaymentForm.subscriptionType.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updatePaymentForm.subscriptionType.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Amount*</label>
                                                <input :disabled="getDisabledAdminPayment" v-model.trim="updatePaymentForm.amount" @blur="$v.updatePaymentForm.amount.$touch()" :class="[{'input-error' : $v.updatePaymentForm.amount.$error }, getDisabledAdminPayment ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type payment amount" autocomplete="off" />
                                                <div v-if="$v.updatePaymentForm.amount.$error">
                                                    <p v-if="!$v.updatePaymentForm.amount.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updatePaymentForm.amount.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" lg="6">
                                            <div class="profile__element">
                                                <label class="profile__label">Date* (timestamp)</label>
                                                <input :disabled="getDisabledAdminPayment" v-model.trim="updatePaymentForm.date" @blur="$v.updatePaymentForm.date.$touch()" :class="[{'input-error' : $v.updatePaymentForm.date.$error }, getDisabledAdminPayment ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type payment date" autocomplete="off" />
                                                <div v-if="$v.updatePaymentForm.date.$error">
                                                    <p v-if="!$v.updatePaymentForm.date.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updatePaymentForm.date.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Customer*</label>
                                                <input :disabled="getDisabledAdminPayment" v-model.trim="updatePaymentForm.customer" @blur="$v.updatePaymentForm.customer.$touch()" :class="[{'input-error' : $v.updatePaymentForm.customer.$error }, getDisabledAdminPayment ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type payment customer" autocomplete="off" />
                                                <div v-if="$v.updatePaymentForm.customer.$error">
                                                    <p v-if="!$v.updatePaymentForm.customer.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updatePaymentForm.customer.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Invoice*</label>
                                                <input :disabled="getDisabledAdminPayment" v-model.trim="updatePaymentForm.invoice" @blur="$v.updatePaymentForm.invoice.$touch()" :class="[{'input-error' : $v.updatePaymentForm.invoice.$error }, getDisabledAdminPayment ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type payment Invoice" autocomplete="off" />
                                                <div v-if="$v.updatePaymentForm.invoice.$error">
                                                    <p v-if="!$v.updatePaymentForm.invoice.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updatePaymentForm.invoice.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12">
                                            <button :disabled="getDisabledAdminPayment" type="submit" class="details-button details-button--red"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Save payment</button>
                                        </b-col>
                                    </b-row>
                                </form>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import { mapGetters } from 'vuex'
import router from '../router/router'
import firebase from 'firebase/app'
import Spinner from 'vue-simple-spinner'
import { required, maxLength } from 'vuelidate/lib/validators'
export default {
  components: {
    SimpleLineIcons,
    Spinner
  },
  data: function () {
    return {
      paymentLoaded: false,
      updatePaymentForm: {
        paymentId: null,
        uid: null,
        transactionId: null,
        subscriptionType: null,
        amount: null,
        date: null,
        customer: null,
        invoice: null,
        localError: null
      }
    }
  },
  validations: {
    updatePaymentForm: {
      uid: {
        required: required,
        maxLength: maxLength(200)
      },
      transactionId: {
        required: required,
        maxLength: maxLength(200)
      },
      subscriptionType: {
        required: required,
        maxLength: maxLength(200)
      },
      amount: {
        required: required,
        maxLength: maxLength(200)
      },
      date: {
        required: required,
        maxLength: maxLength(200)
      },
      customer: {
        required: required,
        maxLength: maxLength(200)
      },
      invoice: {
        required: required,
        maxLength: maxLength(200)
      }
    }
  },
  methods: {
    updateAdminPayment () {
      this.$store.dispatch('setDisabledAdminPayment')
      this.$v.updatePaymentForm.$touch()
      if (!this.$v.updatePaymentForm.$invalid) {
        this.$store.dispatch('updateAdminPayment', { paymentId: this.updatePaymentForm.paymentId, number: this.updatePaymentForm.transactionId, type: this.updatePaymentForm.subscriptionType, amount: this.updatePaymentForm.amount, date: this.updatePaymentForm.date, user: this.updatePaymentForm.uid, customer: this.updatePaymentForm.customer, invoice: this.updatePaymentForm.invoice })
          .then(() => {
            this.scrollToTop(500)
          })
          .catch(error => {
            this.scrollToTop(500)
            this.updatePaymentForm.localError = error.message
            this.$store.dispatch('clearDisabledAdminPayment')
          })
      } else {
        this.scrollToTop(500)
        this.updatePaymentForm.localError = 'Invalid form'
        this.$store.dispatch('clearDisabledAdminPayment')
      }
    },
    scrollToTop (scrollDuration) {
      var scrollStep = -window.scrollY / (scrollDuration / 15)
      var scrollInterval = setInterval(function () {
        if (window.scrollY != 0) {
          window.scrollBy(0, scrollStep)
        } else clearInterval(scrollInterval)
      }, 15)
    }
  },
  beforeCreate () {
    this.$store.dispatch('clearError')
    this.$store.dispatch('clearDisabledAdminPayment')
    this.$store.dispatch('getAdminPaymentsList')
  },
  created () {
    var user = firebase.auth().currentUser
    var paymentsRef = firebase.database().ref('payments')

    paymentsRef.child(this.$route.params.id).on('value', snapshot => {
      (this.updatePaymentForm.paymentId = snapshot.key),
      (this.updatePaymentForm.uid = snapshot.val().user),
      (this.updatePaymentForm.transactionId = snapshot.val().number),
      (this.updatePaymentForm.subscriptionType = snapshot.val().type),
      (this.updatePaymentForm.amount = snapshot.val().amount),
      (this.updatePaymentForm.date = snapshot.val().date),
      (this.updatePaymentForm.customer = snapshot.val().customer),
      (this.updatePaymentForm.invoice = snapshot.val().invoice)

      if (user.uid != process.env.VUE_APP_ADMIN) {
        router.push('/401')
      }

      this.paymentLoaded = true
    })
  },
  computed: {
    ...mapGetters([
      'getAdminPaymentsError',
      'getDisabledAdminPayment'
    ])
  }
}
</script>
